.muscle-groups {
  position: relative;

  width: 100%;
  padding: 0.5rem 1rem;
  overflow: hidden;

  margin: 0 auto;
  min-height: 400px; /* Ensure enough space for the SVG */
  display: flex;
  flex-direction: column;
}

.muscle-groups .labels-container {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding-bottom: 10px;
  -webkit-overflow-scrolling: touch;
  margin-top: 340px; /* Increased space between SVG and labels */
  width: 100%;
  padding: 15px 0; /* Add padding above and below */
  border-top: 1px solid #eee; /* Add a subtle separator */
}

.muscle-groups .muscle-group-column {
  flex: 1 0 25%; /* Take at least 25% of the container width */
  min-width: 80px; /* Ensure minimum width */
  padding-right: 10px;
  border-right: 1px solid #ddd;
  text-align: center;
}

.muscle-groups .muscle-group-column:last-child {
  border-right: none;
}

/* For very small screens, ensure columns are visible */
@media (max-width: 360px) {
  .muscle-groups .muscle-group-column {
    min-width: 70px;
  }
}

.muscle-groups svg {
  position: absolute;
  top: 0.5rem;
  left: 50%;
  transform: translateX(-50%);
  height: 300px;
  width: auto;
  z-index: 100;
}

/* Desktop adjustments */
@media (min-width: 769px) {
  .muscle-groups {
    min-height: 480px; /* Increased to accommodate the extra spacing */
  }
  
  .muscle-groups .labels-container {
    justify-content: space-around;
    margin-top: 370px; /* Increased space for desktop */
  }
  
  .muscle-groups svg {
    height: 350px;
  }
  
  .muscle-groups .muscle-group-column {
    flex: 1;
    max-width: 22%;
  }
}

.muscle-groups h1 {
  display: none;
}

.muscle-groups h2 {
  margin: 0 0 0.5rem;
  letter-spacing: -0.03em;
  text-transform: uppercase;
}

.muscle-groups label + h2 {
  margin-top: 1rem;
}

.muscle-groups label {
  width: auto;
  display: block;
  margin-bottom: 0.5rem;
  cursor: pointer;
  opacity: 0.5;
  position: relative;
  z-index: 200;
  border-left: 5px solid transparent;
  padding-left: 6px;
  margin-left: -11px;
}

.muscle-groups label:hover, 
.muscle-groups label.hover {
  opacity: 1;
  border-color: rgba(51, 51, 51, 0.75);
}

.muscle-groups input:checked + label {
  opacity: 1;
  font-weight: bold;
  color: #dd0000;
}

.muscle-groups svg g[id] path {
  opacity: 0.2;
  transition: opacity 0.25s ease-in-out;
}

.muscle-groups svg g g[id]:hover path {
  cursor: pointer;
  opacity: .5;
  fill: #d80404 !important;
}

.muscle-groups .muscles-helper {
  display: none;
}

/* Muscle group hover and selection styles */
.muscle-groups label[for="biceps"]:hover ~ svg #Biceps path,
.muscle-groups .biceps:checked ~ svg #Biceps path,
.muscle-groups label[for="deltoids"]:hover ~ svg #Deltoids path,
.muscle-groups .deltoids:checked ~ svg #Deltoids path,
.muscle-groups label[for="forearms"]:hover ~ svg #Forearms path,
.muscle-groups .forearms:checked ~ svg #Forearms path,
.muscle-groups label[for="triceps"]:hover ~ svg #Triceps path,
.muscle-groups .triceps:checked ~ svg #Triceps path,
.muscle-groups label[for="trapezius"]:hover ~ svg #Trapezius path,
.muscle-groups .trapezius:checked ~ svg #Trapezius path,
.muscle-groups label[for="lats"]:hover ~ svg #Lats path,
.muscle-groups .lats:checked ~ svg #Lats path,
.muscle-groups label[for="abs"]:hover ~ svg #Abs path,
.muscle-groups .abs:checked ~ svg #Abs path,
.muscle-groups label[for="obliques"]:hover ~ svg #Obliques path,
.muscle-groups .obliques:checked ~ svg #Obliques path,
.muscle-groups label[for="pectorals"]:hover ~ svg #Pectorals path,
.muscle-groups .pectorals:checked ~ svg #Pectorals path,
.muscle-groups label[for="adductors"]:hover ~ svg #Adductors path,
.muscle-groups .adductors:checked ~ svg #Adductors path,
.muscle-groups label[for="calves"]:hover ~ svg #Calves path,
.muscle-groups .calves:checked ~ svg #Calves path,
.muscle-groups label[for="hamstrings"]:hover ~ svg #Hamstrings path,
.muscle-groups .hamstrings:checked ~ svg #Hamstrings path,
.muscle-groups label[for="glutes"]:hover ~ svg #Glutes path,
.muscle-groups .glutes:checked ~ svg #Glutes path,
.muscle-groups label[for="quads"]:hover ~ svg #Quads path,
.muscle-groups .quads:checked ~ svg #Quads path {
  opacity: 0.75;
}

.muscle-groups .biceps:checked ~ svg #Biceps path,
.muscle-groups .deltoids:checked ~ svg #Deltoids path,
.muscle-groups .forearms:checked ~ svg #Forearms path,
.muscle-groups .triceps:checked ~ svg #Triceps path,
.muscle-groups .trapezius:checked ~ svg #Trapezius path,
.muscle-groups .lats:checked ~ svg #Lats path,
.muscle-groups .abs:checked ~ svg #Abs path,
.muscle-groups .obliques:checked ~ svg #Obliques path,
.muscle-groups .pectorals:checked ~ svg #Pectorals path,
.muscle-groups .adductors:checked ~ svg #Adductors path,
.muscle-groups .calves:checked ~ svg #Calves path,
.muscle-groups .hamstrings:checked ~ svg #Hamstrings path,
.muscle-groups .glutes:checked ~ svg #Glutes path,
.muscle-groups .quads:checked ~ svg #Quads path {
  fill: #dd0000 !important;
  opacity: 0.8;
} 